import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After you have an SSH key pair generated and ready to use for accessing your site over SSH/SFTP, you'll just have to add
it to your Atomic account.`}</p>
    <p>{`Here's how to do it:`}</p>
    <YouTube containerClassName="text-center" videoId="47SytjlLCjI" mdxType="YouTube" />
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/dashboard/dashboard-click-my-settings.png" alt="Atomic click My Settings button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` From inside Atomic, click on the `}<strong parentName="p">{`My Settings`}</strong>{` button at the top-right of the page.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/my-settings/my-settings-profile-tab-click-ssh.png" alt="Atomic Account Management click SSH" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Once inside your account's settings, navigate to the SSH section by clicking on the `}<strong parentName="p">{`SSH`}</strong>{` tab.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/my-settings/my-settings-ssh-tab-create-username-filled.png" alt="Atomic Account Management create SSH user fields" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` To set up your SSH user, enter your desired name into the `}<strong parentName="p">{`SSH Username`}</strong>{` field, then click on the `}<strong parentName="p">{`Create Username`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/my-settings/my-settings-ssh-tab-add-key.png" alt="Atomic Account Management add key button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, you'll add your SSH public key. To do so, start by clicking on the `}<strong parentName="p">{`Add Key`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/my-settings/my-settings-ssh-tab-add-public-key-field.png" alt="Atomic Account Management add SSH public key" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`After clicking on the `}<em parentName="p">{`Add Key`}</em>{` button, additional details will appear. To add your public key, you can perform either of the following:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Use the `}<strong parentName="li">{`Select File`}</strong>{` field to upload your SSH public key.`}</li>
          <li parentName="ul">{`Paste the contents of your public key into the empty field.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/my-settings/my-settings-ssh-tab-add-public-key-button.png" alt="Atomic Account Management click Add Public Key button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` After entering your public key by either selecting the file or pasting its contents inside the field, click on the
`}<strong parentName="p">{`Add Public Key`}</strong>{` button to add your new key.`}</p>
      </li>
    </ol>
    <p>{`Now that you've set up your SSH username and public key, you can use your private key to connect to your Pagely account
with your preferred client.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      