import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Atomic control panel allows you to manage several aspects of your Pagely account. Whether you're managing users,
creating additional WordPress sites, or setting up SSH access, you'll likely be interacting with Atomic.`}</p>
    <p>{`Here are a few of the things that you can do inside Atomic:`}</p>
    <ul>
      <li parentName="ul">{`Add additional users, known as `}<em parentName="li">{`Collaborators`}</em>{`.`}</li>
      <li parentName="ul">{`View and change billing information.`}</li>
      <li parentName="ul">{`Access performance data.`}</li>
      <li parentName="ul">{`Create and manage WordPress sites, commonly referred to as `}<em parentName="li">{`apps`}</em>{`.`}</li>
      <li parentName="ul">{`Manage DNS records.`}</li>
      <li parentName="ul">{`Generate, import, and manage SSL certificates.`}</li>
      <li parentName="ul">{`Manage cache and CDN data.`}</li>
      <li parentName="ul">{`Submit support tickets.`}</li>
      <li parentName="ul">{`And much more!`}</li>
    </ul>
    <p>{`Inside this section, we'll give you a tour of the Atomic control panel to help you find what you're looking for when you
need it. Just click on the `}<em parentName="p">{`Continue`}</em>{` button to the bottom-right to get started!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      