import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After you've created your integration within Atomic, you're ready to configure Bitbucket Pipelines to use the Pagely deployment integration. Here's how to set it up.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` These steps are simplified to get you started at Pagely. If you're integrating an existing production site with Pagely or would like more complete options, see our `}<a parentName="em" {...{
          "href": "https://support.pagely.com/hc/en-us/articles/360047149452"
        }}>{`deploying WordPress sites from Bitbucket Pipelines article`}</a>{` on our main documentation site.)`}</em></p>
    <h2>{`Configuring Bitbucket Repository Secrets`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Begin by logging into your Bitbucket account and accessing the repository that you want to deploy automatically to
your site.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/bitbucket/source-menu-repository-settings.png" alt="Bitbucket repo menu settings" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Next, access your repository's settings by clicking on `}<strong parentName="p">{`Repository Settings`}</strong>{` within the left side menu.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/bitbucket/repository-details-menu-variables.png" alt="Bitbucket repository details menu variables" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`To keep the integration credentials secure, we'll be setting up the integration secret as a repository variable. Therefore, click on `}<strong parentName="p">{`Repository Variables`}</strong>{` within the settings area.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/bitbucket/repository-variables-integration-secret-name.png" alt="Screenshot: repository variable name field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Let's start adding the new variable. Start by entering `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`PAGELY_INTEGRATION_SECRET`}</code>{` into the `}<strong parentName="p">{`Name`}</strong>{` field.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/bitbucket/repository-variables-integration-secret-value.png" alt="Screenshot: repository variable secret field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Now we need to set the `}<strong parentName="p">{`Value`}</strong>{` field. Inside this field, enter your `}<strong parentName="p">{`Integration Secret`}</strong>{` that you were given when creating the integration in Atomic.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/bitbucket/repository-variables-integration-secret-secured.png" alt="Screenshot: select the secured checkbox" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`For security purposes, we'll want to mask this variable in the pipeline's logs. To do so, enable the `}<strong parentName="p">{`Secured`}</strong>{` checkbox.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/bitbucket/repository-variables-integration-secret-add.png" alt="Screenshot: click the add button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Now that the variable fields are in place, click on the `}<strong parentName="p">{`Add`}</strong>{` button.`}</p>
      </li>
    </ol>
    <h2>{`Creating a Deployment Pipeline`}</h2>
    <p>{`After your integration secret has been added, it's time to configure your pipeline. To do this, we're going to be using Pagely's deployment image for easy, safe deployments.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Inside your repository's root directory, add a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`bitbucket-pipelines.yml`}</code>{` file with the following contents:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "yaml"
        }}><pre parentName="div" {...{
            "className": "language-yaml"
          }}><code parentName="pre" {...{
              "className": "language-yaml"
            }}><span parentName="code" {...{
                "className": "token comment"
              }}>{`# This is an example pipeline that deploys to Pagely.`}</span>{`
`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`image`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` atlassian/default`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`image`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`4`}</span>{`

`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`pipelines`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`default`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
	`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{` `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`step`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
		`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`script`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
		`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{` `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`pipe`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` pagely/pagely`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`vps`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`deploy`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span>{`
			`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`variables`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
			`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_DEPLOY_DEST`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"/httpdocs"`}</span>{`
			`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_INTEGRATION_SECRET`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` $PAGELY_INTEGRATION_SECRET
			`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_INTEGRATION_ID`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"HhYn7RelGEiYFJz7nzvKl9"`}</span>{`
			`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_APP_ID`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"12345"`}</span>{`
			`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_WORKING_DIR`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` $BITBUCKET_CLONE_DIR`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now we'll need to update a few variables. The variables are as follows:`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`PAGELY_DEPLOY_DEST`}</strong>{`: This sets the destination to deploy to. The example uses `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/httpsdocs`}</code>{`, which will deploy your repository to your site's root directory. If you're deploying a single theme or plugin, you'll modify this to reflect your path, such as `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/httpdocs/wp-content/plugins/example-plugin-dir`}</code>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_INTEGRATION_SECRET`}</strong>{`: This sets the integration secret. You can leave it alone, as the example uses the same name as the variable we set up earlier in this guide.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_INTEGRATION_ID`}</strong>{`: This variable sets the integration that will be used. You'll need to change this to the `}<strong parentName="li">{`Integration ID`}</strong>{` for the integration you created in Atomic.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_APP_ID`}</strong>{`: This variable determines the app that your code will be deployed to. You'll need to change this to reflect your app's ID from Atomic.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_WORKING_DIR`}</strong>{`: This sets the working directory. Unless you're deploying from a custom directory, you can leave this as the clone directory defined in the example.com`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Finally, be sure to check over your settings for any typos, then commit your changes. Every time your changes are pushed to the repository, your pipeline will deploy your changes.`}</p>
      </li>
    </ol>
    <p>{`You're all done setting up automatic deployments from Bitbucket Pipelines. For more information not covered in this quickstart guide, take a look our support documentation for `}<a parentName="p" {...{
        "href": "https://support.pagely.com/hc/en-us/articles/360047149452"
      }}>{`deploying WordPress sites with Bitbucket Pipelines`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      