import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When purchasing an SSL certificate, you'll be asked by your SSL issuer to provide a CSR. Your SSL provider will then use
that CSR to generate your certificate.`}</p>
    <p>{`CSRs can be generated from within the Atomic control panel. Let's walk through the process of generating a new CSR.`}</p>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/dashboard/dashboard-click-ssl.png" alt="Atomic left menu SSL" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Begin by logging into Atomic and accessing the SSL Certificate Manager by clicking on the `}<strong parentName="p">{`SSL`}</strong>{` menu item.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/ssl-manager-add-certificate-button.png" alt="Atomic SSL Certificate Manager Add Certificate button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` From inside the `}<em parentName="p">{`SSL Certificate Manager`}</em>{`, click on the `}<strong parentName="p">{`Add Certificate`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/add-certificate-create-csr.png" alt="Atomic create a CSR" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Once the button is clicked, a new window will appear. Select the option to `}<strong parentName="p">{`Create a CSR`}</strong>{` to begin the process.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/create-csr/create-csr-common-name.png" alt="Atomic CSR select domain" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Inside the window, you'll now be prompted to select your domain. `}<strong parentName="p">{`Select your domain`}</strong>{` from the drop-down, then
click `}<strong parentName="p">{`Continue`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/create-csr/create-csr-required-fields.png" alt="Atomic CSR required fields" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, fill out the required `}<strong parentName="p">{`Common Name`}</strong>{`, `}<strong parentName="p">{`Organization`}</strong>{`, and `}<strong parentName="p">{`Country`}</strong>{` fields.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/create-csr/create-csr-optional-fields.png" alt="Atomic CSR optional fields" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` After submitting the required fields, you'll be presented with a few optional fields. These aren't required by all
SSL providers, but it's generally a good idea to fill them out, just in case they're required by your SSL provider
or certificate type.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/create-csr/create-csr-review-generate-csr.png" alt="Atomic CSR verification step" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Now that you've provided the required and optional information that will be used to generate your CSR, you'll want
to review it all to ensure that you didn't make any typos.`}</p>
        <p parentName="li">{`If everything look good, just click on the `}<strong parentName="p">{`Generate CSR`}</strong>{` button to generate your CSR.`}</p>
      </li>
    </ol>
    <p>{`Now that your CSR has been generated, you'll be shown it's contents and be able to copy it to your clipboard. At this
point, you can go ahead and purchase your SSL certificate.`}</p>
    <p>{`Once your new SSL certificate is issued, you'll be ready to continue on to the next section of this course, which will
show you how to install your new certificate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      