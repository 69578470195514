import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/pressformance/cpu/cpu.png" alt="Atomic Pressformance page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`By clicking on the `}<strong parentName="p">{`PressFORMANCE`}</strong>{` menu item, you're able to take a look at how your WordPress site is performing.`}</p>
    <p>{`Inside the `}<em parentName="p">{`PressFORMANCE`}</em>{` page, you'll see a graphical view of your site's CPU usage, as well as additional filters
that you can apply on a date range or per-site basis.`}</p>
    <p>{`Overall, these usage graphs here are a great way to get an overview of your site has been performing on a server level.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      