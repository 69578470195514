import React from "react";
import { Link } from "gatsby";

import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

function LessonFork({ children, heading }) {
	return (
		<Container className="lesson-fork">
			<div className="lesson-fork-heading">{heading}</div>
			<CardDeck className="lesson-fork-paths">{children}</CardDeck>
		</Container>
	);
}

function Path({ children, href, image }) {
	return (
		<Card className="lesson-fork-path">
			<Link to={href}>
				{image && <Card.Img variant="top" src={image} />}
				<Card.Body>{children}</Card.Body>
			</Link>
		</Card>
	);
}

function PathTitle({ children }) {
	return <Card.Title className="lesson-fork-path-title">{children}</Card.Title>;
}

function PathDescription({ children }) {
	return <Card.Text className="lesson-fork-path-description">{children}</Card.Text>;
}

LessonFork.Path = Path;
LessonFork.PathTitle = PathTitle;
LessonFork.PathDescription = PathDescription;

export default LessonFork;
