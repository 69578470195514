import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After creating staging or development sites, you'll need a way to copy your site's files and database over to your new
environment. To make things easy for you, we've created the Pagely Sync and Pagely Clone tools for you to utilize.`}</p>
    <p>{`In this example, we'll use the
`}<a parentName="p" {...{
        "href": "https://support.pagely.com/hc/en-us/articles/203115924-Cloning-WordPress-Sites-with-Pagely-Sync"
      }}>{`Pagely Sync tool`}</a>{`,
which is useful for one-time syncing from one environment to another. If you need to do more advanced configuration, the
`}<a parentName="p" {...{
        "href": "https://support.pagely.com/hc/en-us/articles/115001695031-Advanced-Config-Guide-Pagely-Clone-Tool"
      }}>{`Pagely Clone tool`}</a>{`
is also an option that you might find useful in the future.`}</p>
    <h2>{`Cloning with the Pagely Sync Tool`}</h2>
    <p>{`Cloning your production site over to your new staging environment is easy with the Pagely Clone tool. Here's how to use
it:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Since the Pagely Sync tool is a CLI-only tool, begin by logging into your server via SSH.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once logged in, let's double-check your production site's table prefix using the following command:`}</p>
        <p parentName="li"><em parentName="p">{`(Don't forget to place example.com with your actual production domain)`}</em></p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`cat`}</span>{` ~/sites/example.com/wp-config.php `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`|`}</span>{` `}<span parentName="code" {...{
                "className": "token function"
              }}>{`grep`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`'$table_prefix'`}</span></code></pre></div>
        <p parentName="li">{`If your table prefix is `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`wp_`}</code>{`, you're all set to move on to the next step!`}</p>
        <p parentName="li">{`If not, you'll need to go ahead and adjust your table prefix in your new staging site's `}<em parentName="p">{`wp-config.php`}</em>{` file to
match the site that your cloning.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Before making any permanent changes, let's go ahead and do a dry run of the Pagely Sync tool by using the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--dry-run`}</code>{`
option:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`pagely `}<span parentName="code" {...{
                "className": "token function"
              }}>{`sync`}</span>{` --dry-run --rename ~/sites/example.com ~/sites/staging.example.com`}</code></pre></div>
        <p parentName="li">{` As you see from the above command, we're defining the source, the target, the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--dry-run`}</code>{` option to perform a test
run, and the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--rename`}</code>{` option to perform a search and replace on the target.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Did the dry run go as planned? Great! Now, all we need to do is remove the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--dry-run`}</code>{` option to sync from production
to staging.`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`pagely `}<span parentName="code" {...{
                "className": "token function"
              }}>{`sync`}</span>{` --rename ~/sites/example.com ~/sites/staging.example.com`}</code></pre></div>
      </li>
    </ol>
    <p>{`As soon as the Pagely Sync tool finishes its operations, your production site should now be successfully cloned over to
your staging environment.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Hint`}</strong>{`: Want to learn more about the Pagely Sync tool? Try running `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`pagely sync --help`}</code>{` or check out the
`}<a parentName="em" {...{
          "href": "https://support.pagely.com/hc/en-us/articles/203115924-Cloning-WordPress-Sites-with-Pagely-Sync"
        }}>{`full Pagely Sync documentation article`}</a>{`.`}</em></p>
    <h2>{`Custom Cloning Profiles with the Pagely Clone Tool`}</h2>
    <p>{`In addition to the Pagely Sync tool, the Pagely Clone tool is also available. Within the Pagely Clone tool, you'll be
able to create more advanced custom configurations that allow you to fine-tune specifics.`}</p>
    <p>{`For this course, we won't be going over the Pagely Clone tool, but you can read more about it
`}<a parentName="p" {...{
        "href": "https://support.pagely.com/hc/en-us/articles/115001695031"
      }}>{`on our documentation site`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      