import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/payment-and-plan/overview/overview.png" alt="Atomic Payment and Plan page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`You can easily access your account's billing details by clicking on the `}<strong parentName="p">{`Payment and Plan`}</strong>{` menu item on the left side
of your Atomic account.`}</p>
    <p>{`From the `}<em parentName="p">{`Payment and Plan`}</em>{` page, you can do things like:`}</p>
    <ul>
      <li parentName="ul">{`Review current and past invoices.`}</li>
      <li parentName="ul">{`Manage your payment methods.`}</li>
      <li parentName="ul">{`View or modify your account's hosting plan and add-ons.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      