import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Generating SSH key pairs in Linux is a breeze. We'll walk you through the process in just a few simple steps.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Start by opening up a new terminal window.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next, use the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`ssh-keygen`}</code>{` command to start generating you key pair:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`ssh-keygen -t rsa`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You'll now be prompted with a series of questions.`}</p>
        <p parentName="li">{`The first of these questions is where you would like to store your key. By default, it will be stored inside your
user's `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.ssh`}</code>{` directory as `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`id_rsa`}</code>{`. Unless you have an existing key there, the default location is fine.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After selecting your key's location, you'll be asked to enter a passphrase for your key.`}</p>
        <p parentName="li"><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` While it is possible to leave this field blank to generate a key without a passphrase, we recommend that
you `}<strong parentName="em">{`always set a passphrase on your SSH keys`}</strong>{`. Setting a passphrase ensures that someone cannot gain access to
your key with just the private key file alone.`}</em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once your passphrase has been entered, your SSH key pair will be generated.`}</p>
        <p parentName="li">{`To access your public key's contents, you can use the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`cat`}</code>{` command like this:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`cat`}</span>{` ~/.ssh/id_rsa.pub`}</code></pre></div>
      </li>
    </ol>
    <p>{`Now that your key pair has been generated and you can access your public key file's contents, you're ready to add it to
your Atomic account!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      