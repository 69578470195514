import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When developing stable, scalable websites, you'll want to use multiple environments as changes to your site progress
through various stages.`}</p>
    <p>{`While all sites are different, the overwhelming majority of WordPress sites will utilize three different environment
types - development, staging, and production.`}</p>
    <h2>{`Development Environments`}</h2>
    <p>{`Development environments are used for untested code and in-progress changes. They will typically exist on the
developer's local machine, but can sometimes also live in a remote location.`}</p>
    <ul>
      <li parentName="ul">{`In general, development environments will contain the following configurations:`}</li>
      <li parentName="ul">{`Caching disabled.`}</li>
      <li parentName="ul">{`CDN disabled.`}</li>
      <li parentName="ul">{`Logging and error display enabled.`}</li>
      <li parentName="ul">{`Debugging (XDebug) enabled.`}</li>
    </ul>
    <h2>{`Staging Environments`}</h2>
    <p>{`Staging environments are used to test code that has been in development recently. After you've made changes within your
development environment and are satisfied with them, you'll push the changes to your staging environment to make sure
everything is still running smoothly.`}</p>
    <p>{`Overall, your staging environment should match your production environment as much as possible, while still revealing
any errors that might be present. Your configuration should look something like this:`}</p>
    <ul>
      <li parentName="ul">{`Caches purged and rebuilt after deploying changes.`}</li>
      <li parentName="ul">{`CDN purged and rebuilt after deploying changes.`}</li>
      <li parentName="ul">{`Logging and error display enabled.`}</li>
    </ul>
    <p>{`We recommend that you always utilize a current staging environment before making any changes to your production site.
Using a staging environment ensures that if there's an issue, you can immediately find it before your website goes live
in production.`}</p>
    <h2>{`Production Environments`}</h2>
    <p>{`Your production environment is where your site lives for the world to see. Because this environment requires stability,
security, and speed, we recommend that you only push well-tested changes through to production. You'll also want to use
the following configuration settings:`}</p>
    <ul>
      <li parentName="ul">{`Caching enabled.`}</li>
      <li parentName="ul">{`CDN enabled.`}</li>
      <li parentName="ul">{`Error display disabled.`}</li>
      <li parentName="ul">{`Debugging disabled.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      