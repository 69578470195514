import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PressArmorLogo from "../../../images/services/pressarmor.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={PressArmorLogo} className="w-50 float-right grayscale" />
    <p>{`The foundation of PressARMOR is our dynamic web application firewall (WAF). By analyzing incoming traffic, our WAF scans
for potential attacks before they're able to reach your WordPress site. Due to the dynamic nature of how our WAF works,
we're able to identify and stop potential threats in their tracks.`}</p>
    <p>{`Even if your WordPress site's data never becomes compromised, DDoS and brute force attacks can be a significant problem.
Massive attacks can cost quite a bit in system resources and trickle down into your bottom line. Since PressARMOR's web
application firewall is running at the network edge level, these threats are stopped before they ever hit your wallet.`}</p>
    <p>{`Of course, it's impossible to block every possible attack attempt. Sometimes, attacks blend in perfectly with typical
site activity. If an attack were to reach your site, our malware scanners are equipped with continually evolving
rulesets that actively monitor all file activity.`}</p>
    <p>{`During your time here at Pagely, if you ever have a security concern, always feel free to reach out to our team. If a
security event were ever to occur, we're always available to assist you with cleaning and restoring your site, free of
charge.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      