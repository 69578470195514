import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/dashboard/dashboard.png" alt="Atomic Dashboard page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`When you first log into the Atomic control panel, you'll be presented with the main dashboard page. This page will give
you an overview of your account, as well as helpful links and Pagely news.`}</p>
    <p>{`Most notably is the Apps section. Each app that is listed here can be clicked on to view further details about it. It
can come in quite handy and save a few clicks when making changes or displaying information about a particular app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      