import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Automatically deploying changes to your code from version control is the best way to maintain your WordPress site. Using
version control ensures that you can track every change to your code and quickly revert them if necessary. At the same
time, automatic deployments from version control will keep your environments up to date at all times.`}</p>
    <p>{`Inside Atomic, we've created an interface that allows you to configure automatic deployments with ease. Here's how to
set up automated deployments for your WordPress apps:`}</p>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/dashboard/dashboard-click-integrations.png" alt="Screenshot: atomic dashboard click integrations" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Log into Atomic, then click on `}<strong parentName="p">{`CI/CD Integrations`}</strong>{` on the left side menu.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/integrations/integrations-create-new-integration-button.png" alt="Screenshot: click on the create new integration button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, we'll need to create a new integration. To do so, click on the `}<strong parentName="p">{`Create New Integation`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/integrations/integrations-create-name-field.png" alt="Screenshot: fill in the integration name field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Let's get started by naming the integration. This can be anything you want it to be, but you'll want to make sure it describes the integration in case you need to reference it later.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/integrations/integrations-create-permissions-field.png" alt="Screenshot: add apps to the permissions drop down" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Now let's select the apps that this integration has access to. Click on the `}<strong parentName="p">{`Permissions`}</strong>{` drop-down and select the app's that you'll be deploying to.`}</p>
        <p parentName="li"><em parentName="p">{`(`}<strong parentName="em">{`Note`}</strong>{`: It's a good idea to limit access as much as possible. If you're deploying multiple apps, it's a good idea to create multiple integrations for them. This way, if one set of credentials were compromised, other apps wouldn't be affected)`}</em></p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/integrations/integrations-create-clear-cache.png" alt="Screenshot: integrations clear cache option" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`You also have the option to automatically clear the cache after a deployment runs. In most scenarios, you'll want this enabled.`}</p>
      </li>
    </ol>
    <Lightbox src="screenshots/atomic/integrations/integrations-details-expanded.png" alt="Screenshot: integration details expanded" className="thumbnail-float-left" mdxType="Lightbox" />
    <p>{`It may take a few moments to create your integration. Once complete, click on the integration to expand its details.`}</p>
    <p>{`Now that you have created your integration, you'll be able to use that information for additional configuration on your
provider's end.`}</p>
    <div style={{
      "clear": "both"
    }}></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      