import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Fast, scalable, and secure hosting is a massive part of running a successful site, but without responsible development
practices, even the best of websites can lag behind the competition.`}</p>
    <p>{`While we couldn't possibly go over the best situation for every specific scenario, a few things that we'll look at
within this course are:`}</p>
    <ul>
      <li parentName="ul">{`Setting up and utilizing multiple environments for development, staging, and production.`}</li>
      <li parentName="ul">{`Using secure, reputable, and well-maintained WordPress plugins and themes.`}</li>
      <li parentName="ul">{`Utilizing version control and automatic deployments.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      