import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Collaborators are used for allowing multiple people to access your Pagely account, without the need to share any login
credentials. In addition to providing separate usernames and passwords, each account is configured with a role to allow
for multiple permission levels.`}</p>
    <p>{`For example, each member of your development team could have separate login credentials for managing the technical
aspects of your Pagely account, without the ability to manage things like billing details. If a member of your team is
added or removed, changes only need to be made to that particular collaborator.`}</p>
    <p>{`Collaborators are a great way to keep your sites secure and easy to manage. For those reasons, we recommend that you
never share login credentials and always utilize Atomic's collaboration features whenever providing access to your
account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      