import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Let's Encrypt is a great solution for those who don't have a definitive reasoning for a premium SSL certificate.`}</p>
    <p>{`Without further ado, let's walk through the process of installing an SSL cerificate with Let's Encrypt!`}</p>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/dashboard/dashboard-click-ssl.png" alt="Atomic left menu SSL" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`From inside the Atomic control panel, access the SSL Certificate Manager by clicking the `}<strong parentName="p">{`SSL`}</strong>{` menu option.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/ssl-manager-add-certificate-button.png" alt="Atomic left menu SSL" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`You'll now see a list of all currently installed SSL certificates.`}</p>
        <p parentName="li">{`Since we'll be adding a new certificate, click on the `}<strong parentName="p">{`Add Certificate`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/add-certificate-lets-encrypt.png" alt="Atomic add certificate Lefts Encrypt" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Then we need to click on `}<strong parentName="p">{`Let's Encrypt`}</strong>{` to add a new certificate from Let's Encrypt.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/lets-encrypt/lets-encrypt-step-1-select-domains.png" alt="Atomic Lets Encrypt select domains" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`We'll now be prompted to select our domain from the drop-down.`}</p>
        <p parentName="li">{`If we want to add additional domains to this same certificate, we can also select those using the checkboxes next to
each domain.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/lets-encrypt/lets-encrypt-step-1-apply-certificate.png" alt="Atomic Lets Encrypt Apply Certificate button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Next, we just need to click on the `}<strong parentName="p">{`Apply Certificate`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/ssl-certificate-manager/add-certificate/lets-encrypt/lets-encrypt-step-3-looks-good-button.png" alt="Atomic Lets Encrypt success message Loogs Good button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`After the `}<em parentName="p">{`Apply Certificate`}</em>{` button is clicked, the installation will begin. When the installation completes,
you'll be prompted to test your site. Be sure to give it a thorough check to make sure that everything is well.`}</p>
        <p parentName="li">{`If everything still looks good, go ahead and click on the `}<strong parentName="p">{`Looks Good`}</strong>{` button to confirm that everything went as
planned.`}</p>
      </li>
    </ol>
    <p>{`It really is that easy to install a Let's Encrypt SSL certificate on your Pagely-hosted WordPress site!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      