import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <YouTube containerClassName="text-center" videoId="Ct9CCC05EHE" mdxType="YouTube" />
    <p>{`In this article, we'll show you how to generate SSH key pairs on Windows by using PuTTYgen.`}</p>
    <h2>{`Downloading PuTTYgen`}</h2>
    <Lightbox src="screenshots/putty/putty-installer-download-page.png" alt="PuTTY installer download page screenshot" className="thumbnail-float-left" mdxType="Lightbox" />
    <p>{`The easiest way to generate SSH keys on Windows is to use PuTTYgen. PuTTYgen is a free, open source GUI for generating
SSH key pairs.`}</p>
    <p>{`To get started with PuTTYgen, just head over to the
`}<a parentName="p" {...{
        "href": "https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html"
      }}>{`PuTTY download page`}</a>{` and grab the full installer for
the PuTTY suite of applications.`}</p>
    <div style={{
      "clear": "both"
    }}></div>
    <h2>{`Generating an SSH Key Pair with PuTTYgen`}</h2>
    <p>{`Once you have PuTTYgen installed and opened, you'll be ready to generate your new SSH key pair.`}</p>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-rsa.png" alt="PuTTYgen key type RSA" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`First, we'll want to take a quick look at the `}<strong parentName="p">{`Parameters`}</strong>{` section at the bottom of the window. Before continuing,
be sure that it's set to `}<strong parentName="p">{`RSA`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-key-bits.png" alt="PuTTYgen key number of bits" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Next, ensure that the `}<strong parentName="p">{`Number of bits in a generated key`}</strong>{` field is set to `}<strong parentName="p">{`2048`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-generate-button.png" alt="PuTTYgen generate button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`After you've double-checked your settings, click on the `}<strong parentName="p">{`Generate`}</strong>{` button to begin the key generation process.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-randomness.png" alt="PuTTYgen generate randomness box" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`PuTTYgen will now need a bit of randomness to securely generate your key and will ask you to move your mouse around
inside the window.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-key-comment.png" alt="PuTTYgen key comment" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`When PuTTYgen has finished generating your key, you'll still need to add a few extra details.`}</p>
        <p parentName="li">{`One of these details is the `}<strong parentName="p">{`Key Comment`}</strong>{` field. While not required, it's generally a good idea to add some sort
of comment here to help you identify the key if you ever need to.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-key-passphrase.png" alt="PuTTYgen key passphrase" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Another optional field is the `}<strong parentName="p">{`Key Passphrase`}</strong>{` field. Although it's not a requirement, it's highly recommended
that you add a passphrase to your key.`}</p>
        <p parentName="li"><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` Although having a passphrase on your key isn't a requirement, we recommend that you `}<strong parentName="em">{`always set a
passphrase on your SSH keys`}</strong>{`. Setting a passphrase ensures that someone cannot gain access to your key with just
the private key file alone.`}</em></p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-save-keys.png" alt="PuTTYgen save keys buttons" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`After your optional settings are set, you're ready to save your key files.`}</p>
        <p parentName="li">{`To do so, click on the `}<strong parentName="p">{`Save Public Key`}</strong>{` and `}<strong parentName="p">{`Save Private Key`}</strong>{` buttons, then save your key files to a safe
place. Just don't forget where you put them!`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/putty/puttygen/puttygen-openssh-public-key.png" alt="PuTTYgen OpenSSH public key" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Lastly, you'll need to grab your public key in OpenSSH format by copying it from the `}<strong parentName="p">{`Public key for pasting into
OpenSSH authorized_keys file`}</strong>{` area.`}</p>
        <p parentName="li">{` Don't forget to grab it! You'll need it again in the next lesson when you add the key to your account in the Atomic
control panel.`}</p>
      </li>
    </ol>
    <p>{`Your SSH public and private keys have now been successfully generated and are ready to be used. In the next section,
we'll show you how to add your public key to your account in the Atomic control panel.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      