import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PressCacheLogo from "../../../images/services/presscache.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={PressCacheLogo} className="w-50 float-right grayscale" />
    <p>{`PressCACHE is the robust caching layer that helps your site's visitors get their content as fast as possible. Rather
than serving cache from inside your WordPress site, PressCACHE works directly with PressDNS to deliver any cached assets
directly from a global network of DNS-routed cache nodes.`}</p>
    <p>{`PressCACHE operates much like a CDN, but for WordPress page output. When an incoming request is detected, cached content
is routed directly to the user on a DNS level, using the cache node that is closest to them.`}</p>
    <p>{`Since the request is handled by a node that is optimized for serving cached content that is close to the visitor's
geographic location, PressCache dramatically speeds up your site, especially when serving a global audience.`}</p>
    <p>{`Thanks to the flexibility of the ARES gateway, PressCACHE's caching behavior can be fully customized to fit the unique
needs of your site. If you ever come across the need to customize the caching behavior of your WordPress site, the
Pagely team is ready to deliver.`}</p>
    <p>{`PressCACHE is included with all Pagely accounts and is automatically enabled when using our PressDNS nameservers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      