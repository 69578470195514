import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Before we can dig deeper into the `}<a parentName="p" {...{
        "href": "https://atomic.pagely.com"
      }}>{`Atomic control panel`}</a>{`, you'll want to make sure that you
have an account set up.`}</p>
    <p>{`To set up your Atomic account, you'll want to check your welcome email for a link that will allow you to create a
password. For security purposes, this is a time-sensitive link, so you'll want to make sure that you create your login
credentials as soon as possible.`}</p>
    <p>{`If you've missed the time window for creating your Atomic login or have forgotten your password, you can always use the
"Forgot Password" link within the `}<a parentName="p" {...{
        "href": "https://atomic.pagely.com"
      }}>{`Atomic login page`}</a>{` to recover your credentials.`}</p>
    <p>{`Once you're able to log in, we're ready to continue exploring Atomic!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      