import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After generating your CSR, you'll be prompted to upload the new SSL certificate that was provided to you by your SSL
provider.`}</p>
    <YouTube containerClassName="text-center" videoId="0ZayRFQq764" mdxType="YouTube" />
    <p><strong parentName="p">{`If you still have the window open from when you generated your CSR`}</strong>{`, you will have already been prompted to upload
your certificate. Simply upload it by selecting your SSL certificate or pasting it's contents inside the field and
submit.`}</p>
    <p><strong parentName="p">{`If you've closed the window after generating your CSR and need to get back to the SSL certificate upload screen`}</strong>{`,
just head back to the `}<em parentName="p">{`SSL Certificate Manager`}</em>{`, then click on `}<em parentName="p">{`Upload Certificate`}</em>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      