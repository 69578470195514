import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/apps/apps-list.png" alt="Atomic Apps page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`At Pagely, we generally refer to individual WordPress sites as `}<strong parentName="p">{`Apps`}</strong>{`. Therefore, to access a listing of the different
environments available on your account, you can click on the `}<em parentName="p">{`Apps`}</em>{` menu option in Atomic.`}</p>
    <p>{`From the main Apps page, you'll see a listing of each app on your account as well as additional details, such as the
site's hostname and status.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      