import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/ssl-certificate-manager/ssl-certificate-manager.png" alt="Atomic SSL Certificate Manager page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`You can access the SSL Certificate Manager by clicking on the `}<strong parentName="p">{`SSL`}</strong>{` menu item in Atomic. On this page, you'll see a
listing of any currently installed SSL certificates, as well as options for installing additional certificates.`}</p>
    <p>{`Some of the things you can do here are:`}</p>
    <ul>
      <li parentName="ul">{`View and manage existing SSL certificates.`}</li>
      <li parentName="ul">{`Generate a CSR for purchasing a 3rd-party SSL certificate.`}</li>
      <li parentName="ul">{`Install a free SSL certificate to your WordPress site with Let's Encrypt.`}</li>
      <li parentName="ul">{`Install other 3rd-party SSL certificates.`}</li>
      <li parentName="ul">{`Change a site's active SSL certificate.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      