import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Once your integration has been created in Atomic, you can configure Buddy to use the Pagely deployment integration with your git repository.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` The instruction below assumes you have already `}<a parentName="em" {...{
          "href": "https://support.pagely.com/hc/en-us/articles/360047056812"
        }}>{`created a new integration in Atomic`}</a>{`.`}</em></p>
    <h2>{`Connect Your Git Repository and Create a Pipeline`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Login into your `}<a parentName="p" {...{
            "href": "https://buddy.works/sign-in"
          }}>{`Buddy`}</a>{` account.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-new-project.png" alt="buddy new project" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Create a new project by clicking on the `}<strong parentName="p">{`New project`}</strong>{` button:`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next, refer to the relevant guide below to connect Buddy to your git repository:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://buddy.works/docs/integrations/github/github"
            }}>{`GitHub`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://buddy.works/docs/integrations/gitlab"
            }}>{`GitLab`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://buddy.works/docs/integrations/bitbucket"
            }}>{`BitBucket`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://buddy.works/guides/how-integrate-buddy-with-custom-repository"
            }}>{`Using a private git repository`}</a></li>
        </ul>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-new-pipeline.png" alt="buddy new pipeline" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Click on the `}<strong parentName="p">{`New pipeline`}</strong>{` button:`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-pipeline-settings.png" alt="buddy pipeline settings" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`In the `}<strong parentName="p">{`New pipeline`}</strong>{` setup:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Add the name (eg. "Deploy to production").`}</li>
          <li parentName="ul">{`Pick the trigger (manual, on code change, or on schedule).`}</li>
          <li parentName="ul">{`Pick the branch.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After clicking `}<strong parentName="p">{`Add pipeline`}</strong>{`, you will be asked to add the first action, but we'll cover that shortly.`}</p>
      </li>
    </ol>
    <h2>{`Set Up Your Integration Secret Key`}</h2>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-main.png" alt="buddy main" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Go to you project and click the `}<strong parentName="p">{`Variables, Keys & Assets`}</strong>{` in the sidebar.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<strong parentName="p">{`Add...`}</strong>{` and select `}<strong parentName="p">{`Add variable`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-add-new-variable.png" alt="buddy add new variable" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Set the name as PAGELY_INTEGRATION_SECRET and fill the value with the Integration Secret that was generated for you in Atomic.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make sure the `}<strong parentName="p">{`encrypted`}</strong>{` checkbox is checked.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Press the `}<strong parentName="p">{`Add this variable button`}</strong>{`.`}</p>
      </li>
    </ol>
    <h2>{`Create a Deployment Pipeline`}</h2>
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-add-pipeline-action.png" alt="buddy add pipeline action" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`In your pipeline, add a new action by clicking the `}<strong parentName="p">{`Add action`}</strong>{` button.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-action-comment.png" alt="buddy action comment" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Next, refer to the `}<strong parentName="p">{`Custom Build`}</strong>{` action. Input is required in the commands section, so you can simply use a comment, such as '#Pagely Deployment'.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-docker-image-settings.png" alt="buddy docket image settings" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Change the docker image to use the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`pagely/pagely-vps-deploy`}</code>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-variables-tab.png" alt="buddy variables tab" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Add the rest of the variables in the `}<strong parentName="p">{`Variables`}</strong>{` tab.`}</p>
        <p parentName="li">{`Remember to set up the:`}</p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`PAGELY_APP_ID`}</code></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`PAGELY_DEPLOY_DEST`}</code></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`PAGELY_INTEGRATION_ID`}</code></li>
        </ul>
        <p parentName="li"><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` If your code is located inside of a separate source directory, you can set it with `}<code parentName="em" {...{
              "className": "language-text"
            }}>{`PAGELY_WORKING_DIR`}</code>{`.`}</em></p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/buddy/buddy-run-code.png" alt="buddy run code" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Lastly, press the `}<strong parentName="p">{`Run`}</strong>{` button to deploy your code.`}</p>
      </li>
    </ol>
    <p>{`You have now completed setting up automatic deployments with Buddy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      