import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After when setting up your new Pagely account, you'll want to create a staging environment for testing changes to your
site before deploying them to production.`}</p>
    <p>{`Creating a staging environment is as simple as creating a new WordPress app inside Atomic, then configuring a few quick
settings.`}</p>
    <h2>{`Creating a New WordPress App for Staging`}</h2>
    <p>{`Let's start by creating a new app to be used for staging.`}</p>
    <YouTube containerClassName="text-center" videoId="M-Sa_jjaNhs" mdxType="YouTube" />
    <ol>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/dashboard/dashboard-click-apps.png" alt="Atomic dashboard page Apps nav" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` From inside the Atomic control panel, look to the left side menu, then click on `}<strong parentName="p">{`Apps`}</strong>{` to access your app list.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/apps/apps-list-click-new-app.png" alt="Atomic Apps page click New App button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Since we'll be creating a new app, click on the `}<strong parentName="p">{`New App`}</strong>{` button at the top-right of the page.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/apps/new-app/staging/new-app-staging.png" alt="Atomic new app staging fields" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, you'll be presented with a few optional fields. For brevity, we won't go through each of these options as the defaults are
suitable for the vast majority of sites. If you'd like to read more information about these options, take a look at the `}<a parentName="p" {...{
            "href": "https://support.pagely.com/hc/en-us/articles/115001622571-Creating-a-Staging-Environment"
          }}>{`full documentation within the Pagely knowledgebase`}</a>{`.`}</p>
        <p parentName="li">{`After any optional fields are filled, click on the `}<strong parentName="p">{`Next`}</strong>{` button to continue.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/apps/new-app/new-app-primary-domain-filled.png" alt="Atomic new app Primary Domain field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` A new area will appear on the page. Inside the `}<strong parentName="p">{`Primary Domain`}</strong>{` field, enter the domain where you'd like your staging
site to live.`}</p>
        <p parentName="li">{`We recommend that you use a subdomain of your main site, such as `}<em parentName="p">{`staging.example.com`}</em>{`, but feel free
to use any available domain or subdomain, as long as it's not the same as your main production site.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/apps/new-app/new-app-pressdns-checked.png" alt="Atomic new app PressDNS checked" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`The third step is to select any domains that will be using PressDNS.`}</p>
        <p parentName="li">{`Unless you're managing DNS elsewhere, you'll likely want to keep this enabled for any domains you're using.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/apps/new-app/new-app-save-and-create.png" alt="Atomic new app save and create" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Now that our settings are all in place, we'll want to confirm here that everything is as expected.`}</p>
        <p parentName="li">{`If everything looks good, all we need to do is click on this `}<strong parentName="p">{`Create App`}</strong>{` button to create our
new staging environment.`}</p>
      </li>
    </ol>
    <p>{`Just a few moments after creating your new staging app, you'll be ready to configure and use it for testing out changes
to your site!`}</p>
    <p>{`In the next section, we'll show you how to manage additional settings so that you'll be able to purge your cache and CDN
whenever new changes are deployed to your site.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      