import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PressDNSLogo from "../../../images/services/pressdns.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={PressDNSLogo} className="w-50 float-right grayscale" />
    <p>{`PressDNS is another critical component of your WordPress site here at Pagely. Instead of sending your traffic through a
single route, PressDNS will route incoming requests dynamically as needed. Because of these dynamic routing features,
traffic to your site can flow smoothly and with minimal latency, regardless of the visitor's geographic location.`}</p>
    <p>{`Unlike other DNS providers, PressDNS works directly with PressCACHE to deliver any cached content as quickly as
possible. When an incoming request is received, PressDNS will serve up cached pages on a DNS level, saving you money on
precious server resources.`}</p>
    <p>{`For high-availability sites across multiple nodes, PressDNS has even further advantages. Since PressDNS is powered by
Amazon's Route53 service, routing traffic across various nodes, based on geographic location or resource usage, is a
breeze.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      