import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After you've created your integration credentials inside of Atomic, you'll need to take that information and
configure GitLab to use a deployment pipeline that pushes your code when changes occur. Here's how to do it.`}</p>
    <p>{`(`}<em parentName="p"><strong parentName="em">{`Note:`}</strong>{` These steps are simplified to get you started at Pagely. If you're integrating an existing production site with Pagely or would like more complete options, see our `}<a parentName="em" {...{
          "href": "https://support.pagely.com/hc/en-us/articles/360047699772"
        }}>{`deploying WordPress sites from GitLab CI`}</a>{` on our main documentation site.)`}</em></p>
    <h2></h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Start by logging into GitLab and accessing the project that you want to deploy.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/gitlab/gitlab-overview-settings-ci.png" alt="Screenshots: click on CI/CD settings" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Within the left side menu of your repository, hover over `}<strong parentName="p">{`Settings`}</strong>{`, then click on `}<strong parentName="p">{`CI/CD`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/gitlab/settings/ci-settings-add-variable.png" alt="GitLab integrations URL field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`From inside the CI/CD Settings page, locate the `}<strong parentName="p">{`Variables`}</strong>{` section and expand it. Once expanded, click on the button that says `}<strong parentName="p">{`Add Variable`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/gitlab/settings/add-variable/add-variable-integration-secret-value.png" alt="Screenshot: add variable key" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` A new window will appear with fields to set for your variable.`}</p>
        <p parentName="li">{` The first field is the `}<strong parentName="p">{`Key`}</strong>{` field. Inside here, we'll enter the name of our variable as `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`PAGELY_INTEGRATION_SECRET`}</code>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/gitlab/settings/add-variable/add-variable-integration-secret-value.png" alt="Screenshot: integration variable value field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Inside the `}<strong parentName="p">{`Value`}</strong>{` field, enter the `}<strong parentName="p">{`Integration Secret`}</strong>{` value that was generated for you when creating your integration in Atomic.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/gitlab/settings/add-variable/add-variable-integration-secret-mask-variable.png" alt="Screenshot: mask variable checkbox" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` For security purposes, we'll want to mask this variable to make sure that it doesn't show in the logs. To do so, enable the `}<strong parentName="p">{`Mask Variable`}</strong>{` option.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/gitlab/settings/add-variable/add-variable-integration-secret-save-button.png" alt="Screenshot: add variable" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Once the fields are set for the new variable, click on the `}<strong parentName="p">{`Add Variable`}</strong>{` button to save it.`}</p>
      </li>
    </ol>
    <h2>{`Creating a Deployment Pipeline`}</h2>
    <p>{`To simplify deployments of your WordPress site, Pagely provides a Docker image that will perform all of the heavy lifting for you. All we'll have to do is configure the deployment pipeline to use it.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Inside of your repository's root directory, create a file named `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.gitlab-ci.yml`}</code>{` and add the following contents to it:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "yaml"
        }}><pre parentName="div" {...{
            "className": "language-yaml"
          }}><code parentName="pre" {...{
              "className": "language-yaml"
            }}><span parentName="code" {...{
                "className": "token key atrule"
              }}>{`image`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` pagely/pagely`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`vps`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{`deploy`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span>{`

`}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`deploy`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`variables`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_DEPLOY_DEST`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"/httpdocs"`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_WORKING_DIR`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"/builds/$CI_PROJECT_PATH"`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_APP_ID`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"12345"`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_INTEGRATION_ID`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"HhYn7RelGEiYFJz7nzvKl9"`}</span>{`
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`PAGELY_INTEGRATION_SECRET`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` $PAGELY_INTEGRATION_SECRET
    `}<span parentName="code" {...{
                "className": "token key atrule"
              }}>{`script`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{`
    `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`-`}</span>{` echo "Deploying $CI_PROJECT_NAME to Pagely app $PAGELY_APP_ID"`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next, we'll need to adjust a few variables to reflect your repository and the deployment destination. These variables are as follows:`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`PAGELY_DEPLOY_DEST`}</strong>{`: This sets the location that your repository will be deployed to. Inside the example, we've set this as `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/httpdocs`}</code>{`, which will deploy the repository to the site's root directory. If you're deploying a single plugin or theme, you'll want to adjust this to something like `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/httpdocs/wp-content/plugins/example-plugin-dir`}</code>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_WORKING_DIR`}</strong>{`: This is the working directory on your repository that you'll be deploying from. Unless you're deploying for a separate build directory, you can leave this as-is.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_APP_ID`}</strong>{`: This is just the ID of the app you'll be deploying to. If you're not sure what this is, you can find it in your App Details page in Atomic.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_INTEGRATION_ID`}</strong>{`: This is the Integration ID that was generated when creating your integration in Atomic.`}</li>
          <li parentName="ul"><strong parentName="li">{`PAGELY_INTEGRATION_SECRET`}</strong>{`: This sets the integration secret. Since we added this as a variable in previous steps, this can be left as-is.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After your values are in place, carefully check them over for any typos, then push it to your GitLab repository.`}</p>
      </li>
    </ol>
    <p>{`With your GitLab CI deployment pipeline now in place, each commit to will be automatically deployed.`}</p>
    <p>{`For more information about our automated deployment system that wasn't covered in this quickstart guide, you can take a look at the full `}<a parentName="p" {...{
        "href": "https://support.pagely.com/hc/en-us/articles/360047699772"
      }}>{`GitLab CI deployment guide`}</a>{` within the Pagely support documentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      