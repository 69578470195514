import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When using development and staging environments, you'll likely want to make changes to the default settings to make
testing much more comfortable. Nobody likes wondering why their changes aren't working, only to find out that it's
because of some old, cached data.`}</p>
    <h2>{`How To Enable Dev Mode`}</h2>
    <p>{`Dev mode is handy for when you need to test or debug changes. Instead of disabling PressCACHE or PressCDN for the entire
site, Dev Mode will set a cookie into your browser session that will disable all caching (including asset caching) and
CDN rewriting for just you.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Log into your WordPress site as an administrator user.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/wordpress/dashboard/dashboard-menu-presscache.png" alt="WordPress Dashboard menu PressCACHE" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` At the top of your left side admin menu, hover over `}<strong parentName="p">{`Pagely`}</strong>{`, then click on `}<strong parentName="p">{`PressCACHE`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/wordpress/presscache/presscache-dev-mode.png" alt="WordPress PressCACHE enable Dev Mode" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Within this PressCACHE settings page, enable the `}<strong parentName="p">{`Dev Mode`}</strong>{` checkbox, then save your settings.`}</p>
      </li>
    </ol>
    <p>{`After enabling Dev Mode, PressCACHE and PressCDN will be disabled for your browser session. Since it applies
specifically to your session, if you clear your cookies, use another browser, or the cookie expires, you'll need to
enable Dev Mode once again.`}</p>
    <h2>{`How To Disable PressCACHE and PressCDN`}</h2>
    <p>{`If you're working on a development environment or attempting to debug an issue within a staging environment, you might
need to disable page caching. Here's how to do it:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Log into your WordPress site as an administrator user.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/wordpress/dashboard/dashboard-menu-presscache.png" alt="WordPress Dashboard menu PressCACHE" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` On the top of the left side of your WordPress admin menu, hover over `}<strong parentName="p">{`Pagely`}</strong>{` and click on `}<strong parentName="p">{`PressCACHE`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/wordpress/presscache/presscache-disable-all-caching.png" alt="WordPress PressCACHE disable all caching" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` From here, click on the `}<strong parentName="p">{`Disable All Caching`}</strong>{` checkbox to disable caching entirely for the site, then save your
settings.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/wordpress/presscache/presscache-menu-presscdn.png" alt="WordPress PressCACHE menu PressCDN" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, let's disable PressCDN. To access the PressCDN options, click on `}<strong parentName="p">{`PressCDN`}</strong>{` on the left side menu, directly
above the PressCACHE option.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/wordpress/presscdn/presscdn-disable.png" alt="WordPress PressCDN disable CDN" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{`Inside the PressCDN settings page, uncheck the `}<strong parentName="p">{`CDN Functionality Enabled`}</strong>{` option, then save your settings.`}</p>
      </li>
    </ol>
    <p>{`That's all there is to it! PressCACHE and PressCDN and now disabled.`}</p>
    <p>{`Of course, disabling PressCACHE or PressCDN isn't recommended for production or staging sites. In those situations where
you might need to debug something in production, you'll likely want to either enable Dev Mode while debugging an issue
or purge the cache/CDN after deploying your updates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      