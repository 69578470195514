import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/dns/dns-zone-list.png" alt="Atomic DNS page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`To access DNS zones on your account, click on the `}<strong parentName="p">{`DNS`}</strong>{` menu option from within the Atomic control panel. This menu
item will take you to the `}<em parentName="p">{`PressDNS`}</em>{` page, where you can view existing DNS zones or even create new ones.`}</p>
    <p>{`Upon clicking on a zone, you'll see a listing of all currently configured records within that PressDNS zone, along with
a set of fields to create a new DNS record.`}</p>
    <p>{`If you're using Google Apps/G Suite, there's even a shortcut button to add the required DNS records to your zone
automatically.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      