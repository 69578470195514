import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`So you need to generate an SSH key, eh? Let's walk you through the process of creating a new SSH key pair on MacOS.`}</p>
    <YouTube containerClassName="text-center" videoId="hKr2xMqU9Sw" mdxType="YouTube" />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Let's begin by opening up a new `}<strong parentName="p">{`Terminal`}</strong>{` window.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To begin generating an SSH key pair, let's enter the following command into the Terminal window:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`ssh-keygen -t rsa`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`First, the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`ssh-keygen`}</code>{` command will ask where to store the file. By default, it will be saved to your user's `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.ssh`}</code>{`
directory as `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`id_rsa`}</code>{`. Unless there's already an existing key there, the default location will work just fine.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Next, we'll be asked for a passphrase. To keep our new key as secure as possible, let's type in a new passphrase
here.`}</p>
        <p parentName="li">{` `}<em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Although it's possible create a key without a passphrase, we highly recommend against doing so. If your
private key were to be compromised, your passphrase is the only thing standing in an attacker's way.`}</em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once a passphrase has been entered, our new SSH key pair has been generated!`}</p>
        <p parentName="li">{`Since we'll need the contents of our public key when we add it to Atomic, let's use this command to copy the
contents of our new public key to the clipboard:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "shell"
        }}><pre parentName="div" {...{
            "className": "language-shell"
          }}><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`pbcopy `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`<`}</span>{` ~/.ssh/id_rsa.pub`}</code></pre></div>
      </li>
    </ol>
    <p>{`Now that our key pair is generated, all we need to do is add our new public key to Atomic.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      