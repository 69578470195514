import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/support/secure-notes/secure-notes-migration-request.png" alt="WordPress site migration form screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`After your Atomic account is all set up and you've added your team members as collaborators, your next step is to
migrate any existing websites over to your shiny new Pagely account.`}</p>
    <p>{`Many of our customers choose to have us migrate their WordPress sites over for them. If you'd like us to perform a
migration for you, all you need to do is fill out and submit the migration request form that's located inside the Atomic
control panel. Our team will be happy to migrate your site free of charge.`}</p>
    <p>{`Of course, you're always free to migrate your site yourself. Once your server has been provisioned, you'll have SSH/SFTP
access to manage your files and database.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      