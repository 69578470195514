import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/support/submit-ticket/submit-ticket.png" alt="Atomic Support page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`The last stop on our tour through the Atomic control panel is the `}<strong parentName="p">{`Support`}</strong>{` page.`}</p>
    <p>{`From here, you can submit new tickets, view existing tickets, or even see things like system status updates.`}</p>
    <p>{`Remember: our team is always here for you. If you need help with anything, feel free to reach out to us at any time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      