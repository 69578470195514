import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Now that you have an understanding of what a collaborator is and the different roles that you can apply, you're ready to
invite a new collaborator to your Pagely account.`}</p>
    <p>{`If you don't currently have additional people who will need to access the account, feel free to skip through this
section. Of course, if you think you might need to add a new collaborator at some point, it's a good idea to take a
quick read through it.`}</p>
    <YouTube containerClassName="text-center" videoId="oME2ijWvU6Q" mdxType="YouTube" />
    <p>{`To add a collaborator to your Pagely account, follow these steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Begin by logging into the Atomic control panel.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/dashboard/dashboard-click-team.png" alt="Atomic Dashboard page Team menu item" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, click on `}<strong parentName="p">{`Team`}</strong>{` from within the left side menu.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/team/team-add-new-collaborator-name-filled" alt="Atomic add new collaborator name field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` From here, you'll be able to see a list of any collaborators that currently exist on your Pagely account.`}</p>
        <p parentName="li">{` Inside the `}<strong parentName="p">{`Add a New Collaborator`}</strong>{` section, let's start adding our new collaborator by filling in the `}<strong parentName="p">{`Name`}</strong>{`
field with the person's name.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/team/team-add-new-collaborator-role.png" alt="Atomic add new collaborator role selection field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Remember the collaborator roles that we talked about in the last section? In this next field, select a role to
associate with the new collaborator.`}</p>
        <p parentName="li">{` `}<strong parentName="p">{`Tip`}</strong>{`: Don't remember all of the permissions available for each role? No problem. Once you select a role within
the drop-down, a summary of what permissions apply to that role will appear.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/team/team-add-new-collaborator-email-filled.png" alt="Atomic add new collaborator email field" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Next, you'll enter the collaborator's email address into the `}<strong parentName="p">{`Email`}</strong>{` field.`}</p>
      </li>
      <li parentName="ol">
        <Lightbox src="screenshots/atomic/team/team-add-new-collaborator-click-invite-button.png" alt="Atomic add new collaborator invite button" className="thumbnail-float-right" mdxType="Lightbox" />
        <p parentName="li">{` Now that all of the fields are filled out, send the invitation by clicking the `}<strong parentName="p">{`Invite`}</strong>{` button.`}</p>
      </li>
    </ol>
    <p>{`Whenever you add a new collaborator, the user will be sent an email that contains a link to set their password. Since
this is a time-sensitive link, you'll want to make sure they complete the signup as soon as possible. If they miss their
time window, you may need to remove and re-invite the collaborator again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      