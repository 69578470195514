import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PressCDNLogo from "../../../images/services/presscdn.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={PressCDNLogo} className="w-50 float-right grayscale" />
    <p>{`PressCDN is Pagely's content delivery network that copies your site's files to locations all around the world. As you
probably already know, CDNs are critical to delivering static content quickly and efficiently to your site's visitors.`}</p>
    <p>{`Because PressCDN was specially developed for Pagely customers, you won't need any extra plugins or additional
configuration. PressCDN works out of the box on all Pagely-hosted WordPress sites without lifting a finger.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      