import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/cdn/cdn.png" alt="Atomic PressCDN zone listing page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`To make any changes to your CDN zones, you can do so by clicking on the `}<strong parentName="p">{`CDN`}</strong>{` menu option within the Atomic control
panel.`}</p>
    <p>{`Inside the CDN Zone Manager, you'll be able to add additional CDN zones or manage existing ones. If you click on one of
the current zones, it will show further details for that zone, as well as an option to purge the CDN's cached data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      