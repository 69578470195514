import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import AresLogo from "../../../images/services/ares-gateway.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={AresLogo} className="w-50 float-right grayscale" />
    <p>{`The ARES gateway is Pagely's custom gateway layer. It's one of the many reasons why we're able to offer the level of
stability and speed that we're known for.`}</p>
    <p>{`One of the biggest reasons that enterprise companies choose Pagely for their WordPress sites is because of our
flexibility and custom-tailored approach. When it comes to important, high-traffic websites, a cookie-cutter approach
just isn't enough.`}</p>
    <p>{`Thanks to ARES, custom configurations are a breeze for our team to customize, deploy, and replicate, based on an
individual site's needs. Whether it's custom routing, cache keys, gateway-level redirects, or a multitude of endless
other possibilities, ARES has you covered.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      