import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/my-settings/my-settings-profile-tab.png" alt="Atomic Account Settings page Profile tab screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`To access your account's settings page, click on the `}<strong parentName="p">{`My Settings`}</strong>{` button on the top-right of the screen.`}</p>
    <p>{`From within your account's settings page, you'll be able to perform actions related to your specific Atomic account. A
few of the things you can do from here are:`}</p>
    <ul>
      <li parentName="ul">{`Change your name and company.`}</li>
      <li parentName="ul">{`Update your email address.`}</li>
      <li parentName="ul">{`Update your password.`}</li>
      <li parentName="ul">{`Set up or change 2-factor authentication.`}</li>
      <li parentName="ul">{`Add and manage SSH keys for your account.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      