import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PressThumbLogo from "../../../images/services/pressthumb.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={PressThumbLogo} className="w-50 float-right grayscale" />
    <p>{`PressTHUMB is Pagely's flexible, on-demand image management and optimization solution. If you have a high number of
images on your WordPress site, PressTHUMB is something that you'll likely want to utilize.`}</p>
    <p>{`Typically, WordPress processes images by creating a few different sizes for you to use, then storing them in the same
location as the rest of your site. While this is perfectly fine for the majority of websites out there, image-heavy
enterprise sites see significant benefits from further optimization.`}</p>
    <p>{`Instead of doing all of the processing and storage inside WordPress, PressTHUMB offloads your image assets for off-site
image optimization and thumbnail creation that occurs in real-time.`}</p>
    <p>{`By using PressTHUMB on image-heavy sites, you're able to save on bandwidth, storage, and page size.`}</p>
    <p>{`PressTHUMB is a drop-in service that is simple to implement and seamlessly integrates with your existing WordPress site.
If you'd like to take advantage of PressTHUMB for image optimization, contact our support team to have it enabled for
you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      