import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/apps/app-details/app-details.png" alt="Atomic App Details page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`If you click on the little settings icon to the right of one of your apps, it will take you to that app's details page.
Inside the App Details page, you'll be able to view and manage settings for that particular WordPress app.`}</p>
    <p>{`We'll go over some of the specifics later in this course, but a few of the things you can do here are:`}</p>
    <ul>
      <li parentName="ul">{`Add, remove, or change the domains that are associated with an app.`}</li>
      <li parentName="ul">{`Create redirects that occur on the ARES gateway level.`}</li>
      <li parentName="ul">{`Review and download your WordPress app's file and database backups.`}</li>
      <li parentName="ul">{`Manage integrations, such as automatic deployments from your version control system.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      