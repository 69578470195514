import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PagelyStack from "../../../images/services/pagely-stack.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`At Pagely, we offer much more than the standard cookie-cutter approach. Since all websites are unique, we believe your
hosting should be too.`}</p>
    <p>{`Rather than the typical LAMP/LEMP stack that you might see elsewhere, Pagely consists of several unique services that
can be customized to meet your individual needs.`}</p>
    <p>{`Pagely's stack consists of the following services:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ARES`}</strong>{` - Pagely's web application gateway.`}</li>
      <li parentName="ul"><strong parentName="li">{`PressARMOR`}</strong>{` - Pagely's unique security layer.`}</li>
      <li parentName="ul"><strong parentName="li">{`PressCACHE`}</strong>{` - Pagely's caching layer.`}</li>
      <li parentName="ul"><strong parentName="li">{`PressCDN`}</strong>{` - Pagely's CDN layer.`}</li>
      <li parentName="ul"><strong parentName="li">{`PressTHUMB`}</strong>{` - Pagely's image optimization layer.`}</li>
    </ul>
    <img src={PagelyStack} className="grayscale" />
    <p>{`Of course, there's much more to each of these services than a few bullet points. In the next section, we'll dig into
each component of the Pagely ecosystem to see what they're all about.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      