import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Now that you understand what collaborators are and why they're important, let's take a moment to learn about the
permissions that are associated with different collaborator roles.`}</p>
    <p>{`Here's a list of the different roles available and the permissions associated with each role.`}</p>
    <div className="table table-hover table-responsive table-sm collaborator-roles-table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Action`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Account Owner`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Super-Admin`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Sub-Admin`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Tech`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Billing`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`App-Only`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`App-Only-Minimal`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td"><em parentName="strong">{`Account`}</em></strong></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage account`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Create Admin users`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage Billing/Tech users`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage site-level users`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage account 2-factor authentication`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Force 2-factor authentication`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td"><em parentName="strong">{`Sites`}</em></strong></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage sites`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage their own SSH/SFTP keys`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`SFTP into sites`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Access backups`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage aliases`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`SSH into VPS servers`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td"><em parentName="strong">{`Billing/Plan`}</em></strong></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage billing details`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage plan and addons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`See billing information`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`See plan and addon details`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td"><em parentName="strong">{`Support`}</em></strong></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`See all tickets`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Submit tickets`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`See their own tickets`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Access live chat`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td"><em parentName="strong">{`Tools`}</em></strong></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage PressDNS`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage PressCDN`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage SSL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Manage maintenance windows`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Access database admin`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`See the log viewer`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☑`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>&nbsp;&nbsp;</p>
    <p>{`It's crucial to understand which roles are available to you and to choose the role that best fits the actions that the
collaborator needs to perform. When it's time to add a new collaborator, you'll want to select a role that gives them
enough access to perform their duties, while restricting access to things that they don't need.`}</p>
    <p>{`For example, if you have a developer on your team that needs to make changes to various technical aspects of your Pagely
account, but doesn't need to change things like billing information, the Tech role is generally the right choice. On the
flip-side, what if a member of your accounting department needs to view invoices or manage billing details from time to
time? The Billing role allows them to access invoices and other accounting-related information, without the risk of
accidental changes that could impact the reliability of your sites.`}</p>
    <p>{`Now that you have an understanding of collaborator roles and the permissions associated with them, you're ready to
continue to the next section, where you'll learn how to add a new collaborator to your Pagely account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      