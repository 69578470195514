import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Lightbox = makeShortcode("Lightbox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Lightbox src="screenshots/atomic/team/team.png" alt="Atomic Team page screenshot" className="thumbnail-float-right" mdxType="Lightbox" />
    <p>{`Let's take a look at how we can manage members of our team within Atomic by clicking on the `}<strong parentName="p">{`Team`}</strong>{` menu option on the
left side of the page.`}</p>
    <p>{`Inside this page, we can add or remove additional team members (often referred to as `}<em parentName="p">{`Collaborators`}</em>{`) that have access
to different things on the main Pagely account, depending on their role.`}</p>
    <p>{`Later in this course, we'll take a more in-depth look at how you can manage and add additional collaborators on your
Pagely account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      